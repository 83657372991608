<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12 position-relative z-index-2">
        <div class="row mb-4">
          <div class="col-lg-9 col-md-6">
            <div class="my-container">
              <div id="main" v-loading="isLoading" style="width: 100%;height: 100%"></div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 " style="display: flex;flex-direction:column;justify-content: space-evenly">
            <mini-statistics-card
                :title="{ text: '今日警報總數', value: todayAlerts}"
                detail="<span class='text-success text-sm font-weight-bolder '>合格</span>"
                :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'dark',
              }"
                style="min-width: 200px"
            />
            <div style="height: 20px"></div>
            <mini-statistics-card
                :title="{ text: '今日揮手總數', value: todayWaves }"
                :detail="`<span class=&quot;text-success text-sm font-weight-bolder&quot;>${dataMap[nursingHouseIndex].wavesText}</span>`"
                :icon="{
                name: 'leaderboard',
                color: 'text-white',
                background: 'success',
              }"
                style="min-width: 200px"
            />
          </div>
          <chart-holder-card
              title="過去一星期警報數"
              update="更新於今日"
              style="margin-top: 50px;"
          >
            <reports-line-chart
                ref="chart"
                :chart="{
                  labels: [
                    getDate(8),
                    getDate(7),
                   getDate(6),
                    getDate(5),
                    getDate(4),
                    getDate(3),
                    getDate(2),
                    getDate(1),
                    getDate(0)
                  ],
                  datasets: {
                    label: '報警數',
                    data: lastSevenDayAltumAlerts,
                  },
                }"
            />
          </chart-holder-card>
        </div>

      </div>
    </div>

    <div class="row"></div>
  </div>
</template>
<script>

import MiniStatisticsCard from "../views/dashboards/components/MiniStatisticsCard.vue";

import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import booking1 from "../assets/img/products/product-1-min.jpg";
import booking2 from "../assets/img/products/product-2-min.jpg";
import booking3 from "../assets/img/products/product-3-min.jpg";
import ChartHolderCard from "@/views/dashboards/components/ChartHolderCard.vue";
import ReportsLineChart from "@/views/dashboards/components/ReportsLineChart.vue";
import * as echarts from 'echarts';
import ServerApi from "@/utils/ServerApi";

export default {
  name: "Home",
  components: {
    ReportsLineChart,
    ChartHolderCard,
    MiniStatisticsCard,
  },
  data() {
    return {
      booking1,
      booking2,
      booking3,
      US,
      DE,
      GB,
      BR,
      roomList: [
        {content: '伯大尼安老院', value: 1},
        {content: '聖方濟各安老院', value: 2},
        {content: '聖瑪利亞安老院', value: 3},
        {content: '頤安護老院', value: 4},
      ],
      dataMap: {
        1: {
          name: "伯大尼安老院",
          peopleStatus: {
            total: 66,
            normal: 56,
            attention: 8,
            hospital: 2
          },
          bmiData: {
            total: 66,
            health: 58,
            light: 5,
            heavy: 3
          },
          alertsToday: 5,
          wavesToday: 10,
          wavesText: "低於平均值的3%"
        },
        2: {
          name: "聖方濟各安老院",
          peopleStatus: {
            total: 51,
            normal: 41,
            attention: 10,
            hospital: 0
          },
          bmiData: {
            total: 51,
            health: 41,
            light: 7,
            heavy: 3
          },
          alertsToday: 6,
          wavesToday: 35,
          wavesText: "高於平均值5%"
        },
        3: {
          name: "聖瑪利亞安老院",
          peopleStatus: {
            total: 56,
            normal: 45,
            attention: 9,
            hospital: 2
          },
          bmiData: {
            total: 56,
            health: 47,
            light: 7,
            heavy: 2
          },
          alertsToday: 7,
          wavesToday: 23,
          wavesText: "高於平均值3%"
        },
        4: {
          name: "頤安護老院",
          peopleStatus: {
            total: 55,
            normal: 51,
            attention: 4,
            hospital: 0
          },
          bmiData: {
            total: 55,
            health: 51,
            light: 3,
            heavy: 1
          },
          alertsToday: 3,
          wavesToday: 24,
          wavesText: "高於平均值2%"
        },

      },
      nursingHouseIndex: 1,
      myChart: null,
      thisMonthData: [],
      lastWeekData: [],
      yesterdayData: [],
      todayDayData: [],
      requestLock: false,
      isLoading: true,
      todayWaves: 0,
      todayAlerts: 0,
      lastSevenDayAltumAlerts: []
    };
  },
  computed: {
    windowResize: {
      get() {
        return this.$store.getters.getWindowResizeEvent;
      }
    }
  },
  watch: {
    nursingHouseIndex: function (newValue, oldValue) {
      console.log(`数据变化了，新的值是: ${newValue}，旧的值是: ${oldValue}`);
      this.$refs.chart.renderChart();
    },
  },
  created() {
    window.clickHandler = this.clickHandler;
    // this.initMap();

  },
  activated() {
    this.getData();
  },
  mounted() {
    this.getData();
    this.$watch(
        () => this.windowResize,
        () => {
          this.getData();
        }
    );
  },
  unmounted() {
  },

  methods: {
    mainChart() {
      if (this.myChart) {
        this.myChart.dispose();
      }
      const chartDom = document.getElementById('main');
      this.myChart = echarts.init(chartDom);
      let option = {
        title: {
          text: '跌倒檢測'
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          },
          formatter: '<strong>{a}</strong> <br/> 發生時段：{b}<br/>次數：{c}'
        },
        legend: {
          data: ['本月跌倒次數', '上週跌倒次數', '昨日跌倒次數', '今日跌倒次數']
        },

        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: [{
          type: 'category',
          // boundaryGap: false,
          data: ['0:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
            '11:00', '12:00', '13:00', '14:00', '15:00', '16:00',
            '17:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [
          {
            name: '上週跌倒次數',
            type: 'bar',
            data: this.lastWeekData
          },
          {
            name: '本月跌倒次數',
            type: 'bar',
            data: this.thisMonthData
          },
          {
            name: '今日跌倒次數',
            type: 'bar',
            data: this.todayDayData
          },
          {
            name: '昨日跌倒次數',
            type: 'bar',
            data: this.yesterdayData
          }
        ]
      };
      option && this.myChart.setOption(option);
    },
    getData() {
      if (!this.requestLock) {
        this.isLoading = true;
        this.requestLock = true;
        ServerApi.homePageAnalyze().then((response) => {
          if (response.status === 0) {
            this.thisMonthData = response.data['thisMonthData'].map(element => element['1'] ? element['1'] : 0);
            this.lastWeekData = response.data['lastWeekData'].map(element => element['1'] ? element['1'] : 0);
            this.yesterdayData = response.data['yesterdayData'].map(element => element['1'] ? element['1'] : 0);
            this.formatLastSevenDayAltumAlerts(response.data['lastSevenDayAltumAlerts']);
            this.formatTodayDayData(response.data['todayDayData']);
          }
          this.mainChart();
          this.isLoading = false;
        })
        setTimeout(() => {
          this.requestLock = false;
        }, 2000)
      }
      this.mainChart();
      this.$refs.chart.renderChart(this.lastSevenDayAltumAlerts);
    },
    getDate(n) {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() - n);
      return this.formatDate(futureDate);
    }, getDateYear(n) {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() - n);
      return this.formatDateYear(futureDate);
    },
    formatDate(date) {
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数，所以+1
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}.${day}`;
    },
    formatDateYear(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatLastSevenDayAltumAlerts(map) {
      let lastSevenDayAltumAlerts = [];
      for (let i = 9; i--; i >= 0) {
        let alerts = 0;
        if (map[this.getDateYear(i)]) {
          map[this.getDateYear(i)].map(element => {
            for (let key in element) {
              alerts += element[key];
            }
          });
          lastSevenDayAltumAlerts.push(alerts);
        } else {
          lastSevenDayAltumAlerts.push(0);
        }
      }
      this.lastSevenDayAltumAlerts = lastSevenDayAltumAlerts;
      this.$refs.chart.renderChart(this.lastSevenDayAltumAlerts);
    },
    formatTodayDayData(dataList) {
      let todayWaves = 0;
      let todayAlerts = 0;
      this.todayDayData = dataList.map(element => {
        const value = element['1'] ? element['1'] : 0;
        let alerts = 0;
        for (let key in element) {
          if (key === '5') {
            todayWaves += element['5'];
          }
          alerts += element[key];
        }
        todayAlerts += alerts;
        return value;
      })
      this.todayAlerts = todayAlerts;
      this.todayWaves = todayWaves;
    }
  }
};
</script>
<style src="./../assets/css/markers.css"></style>
<style scoped src="./../assets/css/Home.css"></style>
