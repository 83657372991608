<template>
  <div ref="closeButton" class="close-box">
    <img src="./../assets/svg/x.svg" alt="" @click="goBack">
  </div>
<!--  <div class="l-row" style="padding: 2px">{{ points }}</div>-->
<!--  <div class="l-row" style="padding: 4px;position: fixed;top: 2px;width:100vw;height: 200px">{{ JSON.stringify(msg) }}</div>-->
  <!--          <canvas ref="triangleCanvas" class="triangle-canvas"></canvas>-->
  <div class="l-col my-full-container" style="align-items: center">
    <img src="./../assets/svg/stamp/head.svg" style="width: 95%;margin-bottom: 50px;margin-top:  80px" alt="">
    <div ref="StampContainer" class="stamp-container">
      <div v-if="stamp === 1" class="l-col l-center show-box" style="width: 100%">
        <img src="./../assets/svg/stamp/logo.svg" class="big-img" alt="">
        <div class="des-box">
          <div class="title">地域電腦有限公司</div>
          <div class="des">成立於2018年，是澳門一家致力於系統開發及互動展品設計的新興科技公司</div>
        </div>
      </div>

      <div v-if="stamp ===2" class="l-col l-center show-box" style="width: 100%">
        <img src="./../assets/svg/stamp/stamp.svg" class="big-img" alt="">
        <div class="des-box">
          <div class="title" style="color: #B84040">智慧印章</div>
          <div class="des">電子印章結合創新科技，為您提供有趣的數位化印章體驗。 輕輕一按，即可蓋上智慧印記</div>
        </div>
      </div>

      <div v-if="stamp ===3" class="l-col l-center show-box" style="width: 100%">
        <img src="./../assets/svg/stamp/vr.svg" class="big-img" alt="">
        <div class="des-box">
          <div class="title" style="color: #007701">VR體驗</div>
          <div class="des">步入未来的印章体验！我们的VR体验电子印章带您进入虚拟现实世界</div>
        </div>
      </div>

      <div v-if="stamp ===4" class="l-col l-center show-box" style="width: 100%">
        <img src="./../assets/svg/stamp/sanba.svg" class="big-img" style="width: 75%" alt="">
        <div class="des-box">
          <div class="title" style="color: #965d5d">大三巴</div>
          <div class="des">探索澳門的歷史魅力！
            我們的大三巴電子印章，結合了傳統與現代設計元素，以大三巴牌坊為靈感來源，為您的文件添加獨特的文化印記
          </div>
        </div>
      </div>

    </div>
    <img src="./../assets/svg/stamp/line.svg" alt="" class="line-svg">
    <div class="l-row" style="justify-content:center;flex-wrap: wrap">
      <div v-if="alreadyList[1]" class="small-box">
        <img src="./../assets/svg/stamp/logo.svg" alt="">
        <div class="des" style="color: #090070">地域電腦有限公司</div>
      </div>
      <div v-if="!alreadyList[1]" class="small-box">
        <img src="./../assets/svg/stamp/logo-dis.svg" alt="">
        <div class="des">地域電腦有限公司</div>
      </div>

      <div v-if="alreadyList[2]" class="small-box">
        <img src="./../assets/svg/stamp/stamp.svg" alt="">
        <div class="des" style="color: #B84040">智慧印章</div>
      </div>
      <div v-if="!alreadyList[2]" class="small-box">
        <img src="./../assets/svg/stamp/stamp-dis.svg" alt="">
        <div class="des">智慧印章</div>
      </div>

      <div v-if="alreadyList[3]" class="small-box">
        <img src="./../assets/svg/stamp/vr.svg" alt="">
        <div class="des" style="color: #007701">VR體驗</div>
      </div>
      <div v-if="!alreadyList[3]" class="small-box">
        <img src="./../assets/svg/stamp/vr-dis.svg" alt="">
        <div class="des">VR體驗</div>
      </div>

      <div v-if="alreadyList[4]" class="small-box">
        <img src="./../assets/svg/stamp/sanba.svg" alt="">
        <div class="des" style="color: #965d5d">大三巴</div>
      </div>
      <div v-if="!alreadyList[4]" class="small-box">
        <img src="./../assets/svg/stamp/sanba-dis.svg" alt="">
        <div class="des">大三巴</div>
      </div>
    </div>
  </div>


</template>
<script>
// import {MessagePlugin} from "tdesign-vue-next";
import {mapMutations} from "vuex";
import StampTool from "@/utils/StampTool";

const body = document.getElementsByTagName("body")[0];
export default {
  name: "SideNavItem",
  components: {},
  data() {
    return {
      touch1: {},
      touch2: {},
      touch3: {},
      canvasWidth: 350,
      canvasHeight: 450,
      ctx: {},
      alreadyList: [],
      stampList: [
        {
          name: "stamp-a",
          value: [132.5994696186919, 107.66957984212743, 119.44615599012913, 90.46857541139828, 89.81621913765328]
        },
        {
          name: "stamp-b",
          value: [90,89.5,136.8,133,90]
        },
      ],
      stamp: 0,
      points: [],
      msg: ""
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  mounted() {
    this.$refs.StampContainer.addEventListener('touchstart', this.handleTouchStart, {passive: false});
    // this.initCanvas();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },

  methods: {
    goBack() {
      console.log(window.getComputedStyle(this.$refs.closeButton).getPropertyValue('opacity'))
      if (window.getComputedStyle(this.$refs.closeButton).getPropertyValue('opacity') > 0) {
        setTimeout(() => {
          this.$router.push("/dashboards/home")
        }, 100)
      }
    },
    handleTouchStart(event) {
      event.preventDefault();
      if (event.touches.length >= 5) {
        const points = [
          {x: event.touches[0].pageX, y: event.touches[0].pageY},
          {x: event.touches[1].pageX, y: event.touches[1].pageY},
          {x: event.touches[2].pageX, y: event.touches[2].pageY},
          {x: event.touches[3].pageX, y: event.touches[3].pageY},
          {x: event.touches[4].pageX, y: event.touches[4].pageY},
        ];
        this.points = points;
        let i = 1;
        this.stampList.forEach((item) => {
          if (StampTool.calFivePoints(item.value, points, 5).isSame) {
            this.stamp = i;
            this.alreadyList[i] = true;
          }
          i++;
        })
      }
    },
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
  },

};
</script>
<style src="./../assets/css/StampDemo.css">

</style>


