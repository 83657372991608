<template>
  <div class="card h-100">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">最近事件</h6>
        </div>
        <div class="col-6 text-end">
          <material-button color="primary" size="sm" variant="outline"
          >查看全部
          </material-button
          >
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0">
      <ul class="list-group">
        <li
            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ getDate(3) }} 09:35:33
            </h6>
            <span class="text-xs">進行了一次揮手</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            <span class="material-symbols-outlined"
                  style="color:#3a6c21;margin: 0 5px 0 10px;">radio_button_checked</span>
            回放
          </div>
        </li>
        <li
            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ getDate(2) }} 07:15:33
            </h6>
            <span class="text-xs">進行了一次揮手</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            <span class="material-symbols-outlined"
                  style="color:#3a6c21;margin: 0 5px 0 10px;">radio_button_checked</span>
            回放
          </div>
        </li>
        <li
            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ getDate(2) }} 13:31:11
            </h6>
            <span class="text-xs">
              <t-tag theme="danger" size="small" style="margin-right: 5px">危險</t-tag>發生了疑似跌倒的事件</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            <span class="material-symbols-outlined"
                  style="color:#3a6c21;margin: 0 5px 0 10px;">radio_button_checked</span>
            回放
          </div>
        </li>
        <li
            class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ getDate(1) }} 11:23:15
            </h6>
            <span class="text-xs">進行了一次揮手</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            <span class="material-symbols-outlined"
                  style="color:#3a6c21;margin: 0 5px 0 10px;">radio_button_checked</span>
            回放
          </div>
        </li>
        <li
            class="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ getDate(0) }} 17:43:13
            </h6>
            <span class="text-xs">
              <t-tag theme="danger" size="small" style="margin-right: 5px">危險</t-tag>發生了疑似跌倒的事件</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            <span class="material-symbols-outlined"
                  style="color:#3a6c21;margin: 0 5px 0 10px;">radio_button_checked</span>
            回放
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "InvoiceCard",
  components: {
    MaterialButton,
  },
  methods: {
    getDate(n) {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() - n);
      return this.formatDate(futureDate);
    }, 
    formatDate(date) {
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数，所以+1
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}.${day}`;
    }
  }
};
</script>
