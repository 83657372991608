<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <t-button style="width: 120px;" theme="success" @click="getData">
        <template #icon>
          <refresh-icon></refresh-icon>
        </template>
        刷新數據
      </t-button>
    </div>
    <div class="row">
      <table class="styled-table">
        <thead>
        <tr>
          <th style="width: 100px;">序號</th>
          <th>設備編號</th>
          <th>設備類型</th>
          <th>在線狀態</th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in tableData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.deviceName }}</td>
          <td>智慧雷達</td>
          <td>
            <t-tag v-if="item.status === 1" theme="success">在線</t-tag>
            <t-tag v-if="item.status === 2" theme="danger">離線</t-tag>
          </td>
          <td>{{ item.roomName }}</td>

        </tr>
        <tr v-if="tableData.length === 0">
          <td v-loading="true" colspan="7" style="height: 80px"></td>
        </tr>
        <!-- and so on... -->
        </tbody>
      </table>
    </div>

  </div>
</template>
<script>
import ServerApi from "@/utils/ServerApi";
import {RefreshIcon} from "tdesign-icons-vue-next";


export default {
  name: "HistoryData",
  components: {RefreshIcon},
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ServerApi.RadarInfo.getAllDevices().then((response) => {
        this.tableData = response.data;
      })
    },

  }
}
</script>

<style scoped>

:global(.my-pagination) {
  --td-brand-color: #009879;
}

:global(.t-select-option.t-is-selected) {
  --td-brand-color: #009879;
  --td-brand-color-light: #eceded;
}

:global(.t-input--focused) {
  --td-brand-color-focus: #eceded;
}

:global(.t-pagination__number:hover) {
  --td-brand-color-hover: #009879;
  --td-brand-color-hover: #009879;
}

.styled-table {
  border-radius: 8px 8px 0 0;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
}

.styled-table th,
.styled-table td {
  padding: 12px 0;
}

.styled-table thead tr th:first-child {
  border-radius: 8px 0 0 0;
}

.styled-table thead tr th:last-child {
  border-radius: 0 8px 0 0;
}


.styled-table tbody tr {
  border-bottom: thin solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr:hover {
  /*font-weight: bold;*/
  color: #009879;
}
</style>