<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <t-button style="width: 120px;" theme="success" @click="getData">
        <template #icon>
          <refresh-icon></refresh-icon>
        </template>
        刷新數據
      </t-button>
    </div>
    <div class="row">
      <table class="styled-table">
        <thead>
        <tr>
          <th style="width: 170px;">時間</th>
          <th style="width: 120px;">報警類型</th>
          <th>報警地點</th>
          <th>報警信息</th>
          <th>跟進信息</th>
          <th style="width: 80px;">狀態</th>
          <th style="width: 160px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item,index) in messageList" :key="index">
          <td>{{ item.timeStamp }}</td>
          <td>
            <t-tag v-if="item.actionType === 'Help Requested'" theme="primary">舉手請求幫助</t-tag>
            <t-tag v-if="item.actionType === 'Fallen'" theme="danger">跌倒</t-tag>
            <t-tag v-if="item.actionType === 'Restricted Zone'" theme="warning">進入限制區域</t-tag>
          </td>
          <td>{{ item.roomName }}</td>
          <td>檢測到 {{ item.personName === 'Someone' ? '有人' : item.personName }} 在 {{ item.roomName }}
            {{ alertContentValueMap[item.actionType] }}
          </td>
          <td>{{ item.alertResolveInfo === null ? '暫無信息' : `${item.alertResolveInfo.resolveUsername} : ${item.alertResolveInfo.resolveContent}` }}</td>
          <td>
            <t-tag v-if="item.status === 1" theme="danger">未處理</t-tag>
            <t-tag v-if="item.status === 2" theme="success">已處理</t-tag>
            <t-tag v-if="item.status === 3" theme="primary">無需處理</t-tag>
          </td>
          <td style="display: flex;justify-content: space-evenly">
            <t-button size="small" style="width: 54px;" theme="primary" @click="showReplayBox(item.alertId)">
              查看
            </t-button>
            <t-button size="small"  style="width: 54px;" theme="success" @click="resolveEvent(item.alertId)">
              跟進
            </t-button>
            <!--            <t-button size="small" theme="danger" @click="openDelDialog(item.alertId)">刪除</t-button>-->
          </td>
        </tr>
        <tr v-if="messageList.length === 0">
          <td v-loading="true" colspan="7" style="height: 80px"></td>
        </tr>
        <!-- and so on... -->
        </tbody>
      </table>
      <div class="l-row">
        <t-pagination v-model="currentPage" v-model:page-size="pageSize" :total="totalPage" class="my-pagination"
                      @current-change="getData" @page-size-change="getData"/>
      </div>
    </div>
    <camera-replay ref="replayBox"></camera-replay>
    <my-resolve-box ref="myResolve"></my-resolve-box>
    <t-dialog header="確定要刪除嗎？" :visible="confirmDialog"
              :on-confirm="delEvent"
              :on-close="closeDelDialog">
      <strong style="color:red;">該操作不可逆</strong>
    </t-dialog>
  </div>
</template>
<script>
import ServerApi from "@/utils/ServerApi";
import {RefreshIcon} from "tdesign-icons-vue-next";
import CameraReplay from "@/components/CameraReplay.vue";
import AlthumApi from "@/utils/AlthumApi";
import MyResolveBox from "@/pages/components/MyResolveBox.vue";
import {MessagePlugin} from "tdesign-vue-next";

export default {
  name: "HistoryData",
  components: {MyResolveBox, CameraReplay, RefreshIcon},
  data() {
    return {
      alertValueMap: {
        "1": "舉手請求幫助",
        "2": "跌倒",
        "3": "進入限制區域"
      },
      alertContentValueMap: {
        "Help Requested": "舉手請求幫助",
        "Fallen": "跌倒",
        "Restricted Zone": "進入限制區域"
      },
      messageList: [],
      confirmDialog: false,
      delId: "",
      currentPage: 1,
      pageSize: 10,
      totalPage: 0
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      ServerApi.getAllAltumAlertsByCurrentPageAndPageSize(this.currentPage, this.pageSize).then((response) => {
        this.messageList = response.data.list;
        this.totalPage = response.data.total;
      })
    },
    showReplayBox(id) {
      this.$refs.replayBox.showDialog(id, () => {
        this.getData();
      });
    },
    resolveEvent(id) {
      AlthumApi.getAlertByAlertId(id)
          .then((res) => {
            let data = JSON.parse(res.data);
            this.$refs.myResolve.resolveEvent(id, data.alert, () => {
              this.getData();
              MessagePlugin.success({content: '跟進成功！', duration: 1000});
            });
          })
    },
    openDelDialog(id) {
      this.confirmDialog = true;
      this.delId = id;
    },
    closeDelDialog() {
      this.confirmDialog = false;
    },
    delEvent() {
      ServerApi.deleteAltumAlertByAltumAlertId(this.delId).then((response) => {
        if (response.status === 0) {
          MessagePlugin.success({content: '刪除成功！', duration: 1000});
          this.confirmDialog = false;
          this.getData();
        }
      })
    }
  }
}
</script>

<style scoped>

:global(.my-pagination) {
  --td-brand-color: #009879;
}

:global(.t-select-option.t-is-selected) {
  --td-brand-color: #009879;
  --td-brand-color-light: #eceded;
}

:global(.t-input--focused) {
  --td-brand-color-focus: #eceded;
}

:global(.t-pagination__number:hover) {
  --td-brand-color-hover: #009879;
  --td-brand-color-hover: #009879;
}

.styled-table {
  border-radius: 8px 8px 0 0;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
}

.styled-table th,
.styled-table td {
  padding: 12px 0;
}

.styled-table thead tr th:first-child {
  border-radius: 8px 0 0 0;
}

.styled-table thead tr th:last-child {
  border-radius: 0 8px 0 0;
}


.styled-table tbody tr {
  border-bottom: thin solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr:hover {
  /*font-weight: bold;*/
  color: #009879;
}
</style>