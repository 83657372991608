function calculateAngle(p1, p2, p3) {
    const v1 = {x: p1.x - p2.x, y: p1.y - p2.y};
    const v2 = {x: p3.x - p2.x, y: p3.y - p2.y};
    const dotProduct = v1.x * v2.x + v1.y * v2.y;
    const normV1 = Math.hypot(v1.x, v1.y);
    const normV2 = Math.hypot(v2.x, v2.y);
    return Math.acos(dotProduct / (normV1 * normV2)) * (180 / Math.PI);
}

function calculateDistance(p1, p2) {
    return Math.hypot(p2.x - p1.x, p2.y - p1.y);
}

function sortPointsByAngle(points) {
    const lowestPoint = points.reduce((prev, current) =>
        (prev.y < current.y || (prev.y === current.y && prev.x < current.x)) ? prev : current);
    const lpX = lowestPoint.x, lpY = lowestPoint.y;
    return points.sort((a, b) =>
        Math.atan2(a.y - lpY, a.x - lpX) - Math.atan2(b.y - lpY, b.x - lpX));
}

function arePentagonsSimilarWithRotation(angles1, angles2, angleTolerance = 5.0) {
    return angles1.some((_, i) =>
        rotateArray(angles2, i).every((angle, index) => Math.abs(angle - angles1[index]) <= angleTolerance));
}

function rotateArray(arr, count) {
    return arr.slice(count).concat(arr.slice(0, count));
}

function calculatePentagonProperties(points) {
    const sortedPoints = sortPointsByAngle(points);
    const angles = [];
    const sides = [];
    for (let i = 0; i < sortedPoints.length; i++) {
        const p1 = sortedPoints[i];
        const p2 = sortedPoints[(i + 1) % sortedPoints.length];
        const p3 = sortedPoints[(i + 2) % sortedPoints.length];
        angles.push(calculateAngle(p1, p2, p3));
        sides.push(calculateDistance(p1, p2));
    }
    return {angles, sides};
}

const stampTool = {
    calFivePoints(angleArray, points,tolerance) {
        const pentagonProperties = calculatePentagonProperties(points);
        return {
            isSame: arePentagonsSimilarWithRotation(angleArray, pentagonProperties.angles,tolerance),
            angles: pentagonProperties.angles
        };
    }
}

export default stampTool;
