<template>
  <div>
    <h1>Server-Sent Events</h1>
    <ul>
      {{ messages }}
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: "", // 用于存储从服务器接收的消息
    };
  },
  created() {
    this.initSSE();
  },
  beforeUnmount() {
    if (this.eventSource) {
      this.eventSource.close(); // 在组件销毁前关闭SSE连接
    }
  },
  methods: {
    initSSE() {
      const eventSource = new EventSource('http://localhost:8001/SSE/stream-sse'); // 更改为你的服务器地址
      eventSource.onmessage = (event) => {
        const newMessage = {
          id: this.messages.length + 1,
          content: event.data,
        };
        this.messages += newMessage.content; // 将新消息添加到messages数组
      };

      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        eventSource.close(); // 出错时关闭连接
      };

      eventSource.onopen = () => {
        console.log('Connection to server opened.');
      };
    },

  },

};
</script>

<style scoped>
/* 添加你的样式 */
</style>
