<template>
  <div
      class="input-group"
      :class="`input-group-${variant} ${getStatus(error, success)}`"
  >
    <h6 v-if="isH6Label">{{ label }}</h6>
    <label v-if="!isH6Label" :class="variant === 'static' ? '' : 'form-label'">{{ label }}</label>
    <input
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses(size)"
        :name="name"
        :value="modelValue"
        :placeholder="placeholder"
        :isRequired="isRequired"
        :disabled="disabled"
        @input="updateValue"
    />
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";

export default {
  name: "MaterialInput",

  props: {
    variant: {
      type: String,
      default: "outline",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isH6Label: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    setMaterialInput();
  },
  methods: {
    getClasses: (size) => {
      let sizeValue;
      sizeValue = size ? `form-control-${size}` : null;
      return sizeValue;
    },
    getStatus: (error, success) => {
      let isValidValue;
      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }
      return isValidValue;
    },
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    }
  }
};
</script>
