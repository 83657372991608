<template>
  <div class="container-fluid ">
    <div class="l-row" style="margin-bottom: 10px;">
      <t-dropdown :options="roomList" :max-column-width="240" :max-height="200" @click="changeNursingHomeId">
        <t-button theme="success" style="margin-left: auto;margin-right: 10px;">
          {{ roomName ? roomName : '院舍' }}
          <template #suffix>
            <chevron-down-icon size="16"/>
          </template>
        </t-button>
      </t-dropdown>
      <t-dropdown :options="peopleList[currentNursingHomeId]" :max-column-width="240" :max-height="200"
                  @click="choosePeople">
        <t-button theme="primary">
          {{ peopleName ? peopleName : '院友' }}
          <template #suffix>
            <chevron-down-icon size="16"/>
          </template>
        </t-button>
      </t-dropdown>

    </div>
    <div class="row">
      <div class="card card-body col-xl-4">
        <div class="row gx-4">
          <div class="col-auto">
            <div class="avatar avatar-xl position-relative">
              <img
                  :src="nursingHomePatients[currentPeople].imgUrl"
                  alt="profile_image"
                  class="shadow-sm w-100 border-radius-lg"
                  style="margin-left: 10px;"
              />
            </div>
          </div>
          <div class="col-auto my-auto">
            <div class="row">
              <div class="card card-plain h-100">
                <div class=" pb-0 card-header" style="padding: 0;margin-top: 10px;margin-left: 5px;">
                  <div class="l-row"><span class="material-symbols-outlined"
                                           style="color:#3fbd00;margin: 0 5px 0 10px;">radio_button_checked</span>
                    <h6 class="mb-0">{{ nursingHomePatients[currentPeople].name }}</h6></div>

                </div>
                <div class="p-3 card-body">
                  <h5>
                    個人信息
                  </h5>

                  <ul class="list-group">
                    <li class="px-0 border-0 list-group-item">
                      年齡：{{ nursingHomePatients[currentPeople].age }}歲
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      性別：{{ nursingHomePatients[currentPeople].gender }}
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      病史：{{ nursingHomePatients[currentPeople].medicalHistory }}
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      身高：{{ nursingHomePatients[currentPeople].height }}
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      體重：{{ nursingHomePatients[currentPeople].weight }}
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      血型：{{ nursingHomePatients[currentPeople].bloodType }}
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      聯繫人：{{ nursingHomePatients[currentPeople].contact.name }}
                      {{ nursingHomePatients[currentPeople].contact.relation }}
                    </li>
                    <li class="px-0 border-0 list-group-item">
                      聯繫方式：{{ nursingHomePatients[currentPeople].contact.phone }}
                    </li>
                  </ul>
                </div>
                <div style="margin-left: auto;">更新日期 {{ getCurrentDateFormatted() }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <div class="row">
          <div class="col-md-6">
            <default-info-card
                icon="hotel"
                title="睡眠時間"
                description="昨日夜間睡眠時間統計"
                :value="nursingHomePatients[currentPeople].sleepHours+'h'"
                style="margin-bottom: 10px;"
            />
            <default-info-card
                icon="bar_chart"
                title="BMI指數"
                description="近一個月BMI測試指數"
                :value="`BMI ${nursingHomePatients[currentPeople].bmi} - ${nursingHomePatients[currentPeople].healthStatus}`"
            />
          </div>
          <div class="col-md-6">
            <default-info-card
                icon="ecg_heart"
                title="血壓"
                description="近期血壓均值"
                :value="nursingHomePatients[currentPeople].bloodPressure"
                style="margin-bottom: 10px;"
            />
            <default-info-card
                icon="falling"
                title="每月跌倒次數"
                description="近一個月跌倒次數"
                :value="`${nursingHomePatients[currentPeople].monthlyFallCount}次`"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <events-card></events-card>
      </div>
      <div class="col-md-12 mt-4">
        <transaction-card/>
      </div>
    </div>

  </div>

  <div class="mt-4 row">
    <div class="col-md-6 col-lg-6">
      <div class="card z-index-2 mt-4">
        <div class="card-header p-3 pt-2">
          <div
              class="l-row l-center icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start"
          >
            <span class="material-symbols-outlined">insights</span>

          </div>
          <div class="d-block d-md-flex">
            <div class="me-auto">
              <h6 class="mb-0">近一週健康行為表</h6>
              <p class="mb-0 text-sm">時間統計</p>
            </div>
            <span
                class="badge badge-lg badge-dot me-4 d-inline-block text-start"
            >
                <i class="bg-success"></i>
                <span class="text-dark">睡眠</span>
              </span>
            <span
                class="badge badge-lg badge-dot me-4 d-inline-block text-start"
            >
                <i class="bg-dark"></i>
                <span class="text-dark">運動</span>
              </span>
            <span
                class="badge badge-lg badge-dot me-4 d-inline-block text-start"
            >
                <i class="bg-info"></i>
                <span class="text-dark">護理</span>
              </span>
          </div>
        </div>
        <div class="p-3 card-body">
          <div class="chart">
            <default-line-chart
                id="line-chart"
                title="健康數據"
                :chart="{
                  labels: [
                  getDate(8),
                    getDate(7),
                   getDate(6),
                    getDate(5),
                    getDate(4),
                    getDate(3),
                    getDate(2),
                    getDate(1),
                    getDate(0)
                ],
                  datasets: [
                    {
                      label: '睡眠時長（h）',
                      data: [6.6, 8.5, 9,7.6, 8.5, 7.6, 7.7, 7.9, 7.6]
                    },
                    {
                      label: '運動時長（h）',
                      data: [3.6, 2.5, 3,4, 4.5, 4.6, 4.7, 3.9, 2.6]
                    },
                    {
                      label: '護理時長（h）',
                      data: [2.5,2.3,3.1,3.5,3.6,3.7,2.6,2.9,3.1]
                    }
                  ]
                }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-lg-6 mt-md-0 mt-4">
      <div class="card z-index-2 mt-4">
        <div class="card-header p-3 pt-2">
          <div
              class="l-row l-center icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 me-3 float-start"
          >
            <span class="material-symbols-outlined">diversity_1</span>

          </div>
          <div class="d-block d-md-flex">
            <div class="me-auto">
              <h6 class="mb-0">安全檢測表</h6>
              <p class="mb-0 text-sm">次數統計</p>
            </div>
            <span
                class="badge badge-lg badge-dot me-4 d-inline-block text-start"
            >
                <i class="bg-success"></i>
                <span class="text-dark">跌倒</span>
              </span>
            <span
                class="badge badge-lg badge-dot me-4 d-inline-block text-start"
            >
                <i class="bg-dark"></i>
                <span class="text-dark">揮手</span>
              </span>
          </div>
        </div>
        <div class="p-3 card-body">
          <gradient-line-chart
              id="chart-line"
              title="Line chart with gradient"
              :chart="{
                labels: [
                  getDate(8),
                    getDate(7),
                   getDate(6),
                    getDate(5),
                    getDate(4),
                    getDate(3),
                    getDate(2),
                    getDate(1),
                    getDate(0)
                ],
                datasets: [
                  {
                    label: '跌倒次數',
                    data: [2,1,0,1,2,1,0,1,1]
                  },
                  {
                    label: '揮手次數',
                    data: [1,4,3,5,4,6,7,2,4]
                  }
                ]
              }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "./components/cards/DefaultInfoCard.vue";
import TransactionCard from "./components/cards/TransactionCard.vue";
import EventsCard from "./components/cards/EventsCard.vue";
import DefaultLineChart from "@/examples/Charts/DefaultLineChart.vue";
import GradientLineChart from "@/views/pages/components/GradientLineChart.vue";
import {ChevronDownIcon} from "tdesign-icons-vue-next";


export default {
  name: "Billing",
  components: {
    ChevronDownIcon,
    GradientLineChart,
    DefaultLineChart,
    EventsCard,
    DefaultInfoCard,
    TransactionCard,
  },
  data() {
    return {
      roomList: [
        {content: '伯大尼安老院', value: 1},
        {content: '聖方濟各安老院', value: 2},
        {content: '聖瑪利亞安老院', value: 3},
        {content: '頤安護老院', value: 4},
      ],
      roomName: "",
      peopleName: "",
      peopleList: {
        1: [
          {content: '鄭偉', value: 1},
          {content: '張吉加', value: 2},
          {content: '李小婷', value: 2},
          {content: '張國文', value: 2},
          {content: '何美麗', value: 2},
          {content: '周月明', value: 2},
          {content: '馬向發', value: 2},
        ],

        2: [
          {content: '鄺物士', value: 3},
          {content: '劉申士', value: 4},
          {content: '陳三好', value: 4},
          {content: '黃愛', value: 4},
          {content: '吳大強', value: 4},
          {content: '林樹榮', value: 4},
          {content: '王必勤', value: 4},
        ]
      },
      nursingHomePatients: {
        1: {
          imgUrl: "https://letoy-community-1311015785.cos.ap-hongkong.myqcloud.com/SmartHome%2F%E4%BC%AF%E5%A4%A7%E5%B0%BC%E5%AE%89%E8%80%81%E9%99%A2_%E9%99%A2%E5%8F%8B1_%E9%84%AD%E5%81%89.jpeg",
          name: "鄭偉",
          age: 65,
          gender: "男",
          height: 168,
          weight: 67,
          bloodType: "AB型",
          medicalHistory: "曾患有心臟血管和高血壓等疾病，在鏡湖醫院有3個月的就診史。",
          contact: {name: "鄭耳心", relation: "女兒", phone: "6882 9955"},
          sleepHours: 6.3,
          bloodPressure: "122 - 80",
          bmi: 23.7,
          healthStatus: "健康",
          monthlyFallCount: 5
        },
        2: {
          imgUrl: "https://letoy-community-1311015785.cos.ap-hongkong.myqcloud.com/SmartHome%2F%E4%BC%AF%E5%A4%A7%E5%B0%BC%E5%AE%89%E8%80%81%E9%99%A2_%E9%99%A2%E5%8F%8B2_%E5%BC%B5%E5%90%89%E5%8A%A0.jpeg",
          name: "張吉加",
          age: 85,
          gender: "女",
          height: 155,
          weight: 43,
          bloodType: "O型",
          medicalHistory: "曾患有糖尿病，視力不佳，在鏡湖醫院做過1次手術及住院1個月。",
          contact: {name: "雷景頁", relation: "兒子", phone: "6698 5877"},
          sleepHours: 7.5,
          bloodPressure: "115 - 85",
          bmi: 17.8,
          healthStatus: "過輕",
          monthlyFallCount: 2
        },
        3: {
          imgUrl: "https://letoy-community-1311015785.cos.ap-hongkong.myqcloud.com/SmartHome%2F%E8%81%96%E6%96%B9%E6%BF%9F%E5%90%84%E5%AE%89%E8%80%81%E9%99%A2_%E9%99%A2%E5%8F%8B1_%E9%84%BA%E7%89%A9%E5%A3%AB.jpeg",
          name: "鄺物士",
          age: 78,
          gender: "男",
          height: 170,
          weight: 80,
          bloodType: "A型",
          medicalHistory: "患有老年癡呆症及曾不慎跌倒導致左小腿骨折。",
          contact: {name: "鄺俊豪", relation: "侄子", phone: "6668 7233"},
          sleepHours: 8,
          bloodPressure: "120 - 70",
          bmi: 27.6,
          healthStatus: "過重",
          monthlyFallCount: 15
        },
        4: {
          imgUrl: "https://letoy-community-1311015785.cos.ap-hongkong.myqcloud.com/SmartHome%2F%E8%81%96%E6%96%B9%E6%BF%9F%E5%90%84%E5%AE%89%E8%80%81%E9%99%A2_%E9%99%A2%E5%8F%8B2_%E5%8A%89%E7%94%B3%E5%A3%AB.jpeg",
          name: "劉申士",
          age: 72,
          gender: "女",
          height: 152,
          weight: 50,
          bloodType: "B型",
          medicalHistory: "患有慢性阻塞性肺病和哮喘，出現呼吸困難和其他呼吸問題。",
          contact: {name: "黃霖霖", relation: "女兒", phone: "6336 9889"},
          sleepHours: 6,
          bloodPressure: "113 - 76",
          bmi: 21.6,
          healthStatus: "健康",
          monthlyFallCount: 3
        },
      },
      currentPeople: 1,
      currentNursingHomeId: 1
    }
  },
  methods: {
    choosePeople(data) {
      this.currentPeople = data.value;
      this.peopleName = data.content;
    },
    changeNursingHomeId(data) {
      this.currentNursingHomeId = data.value;
      this.roomName = data.content;
    },
    getCurrentDateFormatted() {
      const today = new Date();
      const year = today.getFullYear();
      // getMonth() 方法返回的月份是从0开始的，因此需要+1
      const month = String(today.getMonth() + 1).padStart(2, '0'); // 月份前面补零
      const day = String(today.getDate()).padStart(2, '0'); // 日期前面补零
      return `${year}.${month}.${day}`;
    },
    getDate(n) {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() - n);
      return this.formatDate(futureDate);
    },
    formatDate(date) {
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数，所以+1
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}.${day}`;
    }

  }
};
</script>
