<template>
  <div class="card mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">護理追蹤</h6>
        </div>
        <div class="col-6 text-end">
          <material-button color="dark" variant="gradient">
            <i class="fas fa-plus me-2"></i>
            新增指派
          </material-button>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="col-md-6 mb-md-0 mb-4">
          <div
              class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
          >
            <span class="material-symbols-outlined w-10 me-3 mb-0" style="color: #3f8dde">person_apron</span>
            <h6 class="mb-0">
              {{ getDate(0) }} 09:35:12<br>指派 護工 吳美珍、容笑笑 進行理療
            </h6>
            <i
                class="fas fa-pencil-alt ms-auto text-dark cursor-pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title
                aria-hidden="true"
                data-bs-original-title="Edit Card"
                aria-label="Edit Card"
            ></i>
            <span class="sr-only">Edit Card</span>
          </div>
        </div>
        <div class="col-md-6">
          <div
              class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
          >
            <span class="material-symbols-outlined w-10 me-3 mb-0" style="color: #3a6c21">local_police</span>
            <h6 class="mb-0">
              {{ getDate(0) }} 11:35:12<br>指派 保安 郭大牛 進行特殊情況查看
            </h6>
            <i
                class="fas fa-pencil-alt ms-auto text-dark cursor-pointer"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title
                aria-hidden="true"
                data-bs-original-title="Edit Card"
                aria-label="Edit Card"
            ></i>
            <span class="sr-only">Edit Card</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "PaymentCard",
  components: {
    MaterialButton,
  },
  data() {
    return {};
  },
  methods:{
    getDate(n) {
      const today = new Date();
      const futureDate = new Date(today);
      futureDate.setDate(today.getDate() - n);
      return this.formatDate(futureDate);
    },
    formatDate(date) {
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数，所以+1
      const day = String(date.getDate()).padStart(2, '0');
      return `${month}.${day}`;
    }
  }
};
</script>
