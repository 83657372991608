<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0">
        <div id="profile" class="card card-body mt-4" style=" background: #cecece;">
          <!--          <div class="l-row">{{ touch1 }} </div>-->
          <!--          <canvas ref="triangleCanvas" class="triangle-canvas"></canvas>-->
          <div class="l-row l-center">
            <div ref="StampContainer" class="stamp-container">
              <!--First Bird-->
              <div v-if="stamp ===1" class="bird-card show-box">
                <div class="bird-one">
                  <div class="beak"></div>
                  <div class="neck">
                    <div class="cir-blue"></div>
                  </div>
                  <div class="feathers">
                    <div class="feather fe1"></div>
                    <div class="feather fe2"></div>
                    <div class="feather fe3"></div>
                  </div>
                  <div class="eye"></div>
                </div>
                <div class="content color1">
                  <div>
                    <h1>Swesco</h1>
                    <h6>CREATIVE MINTS</h6>
                  </div>
                  <div>50</div>
                </div>
              </div>
              <!--Second Bird-->
              <div v-if="stamp ===2" class="bird-card show-box">
                <div class="bird-two">
                  <div class="beak-two"></div>
                  <div class="head">
                    <div class="orange-sqr"></div>
                    <div class="green"></div>
                    <div class="yellow"></div>
                    <div class="green-feather b2f1"></div>
                    <div class="green-feather b2f2"></div>
                    <div class="green-feather b2f3"></div>
                  </div>
                  <div class="eye-two"></div>
                </div>
                <div class="content color2">
                  <div>
                    <h1>Calisto</h1>
                    <h6>CREATIVE MINTS</h6>
                  </div>
                  <div>30</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {MessagePlugin} from "tdesign-vue-next";

export default {
  name: "SideNavItem",
  components: {},
  data() {
    return {
      touch1: {},
      touch2: {},
      touch3: {},
      canvasWidth: 350,
      canvasHeight: 450,
      ctx: {},
      triangles: [
        {
          name: "stamp-a",
          value: [52.4483, 63.725, 63.826]
        }, {
          name: "stamp-b",
          value: [44.519, 44.914, 90.565]
        },
      ],
      stamp: 0
    };
  },
  mounted() {
    this.$refs.StampContainer.addEventListener('touchstart', this.handleTouchStart, {passive: false});
    // this.initCanvas();
  },

  methods: {
    handleTouchStart(event) {
      event.preventDefault();
      if (event.touches.length >= 3) {
        const points = [
          {x: event.touches[0].pageX, y: event.touches[0].pageY},
          {x: event.touches[1].pageX, y: event.touches[1].pageY},
          {x: event.touches[2].pageX, y: event.touches[2].pageY},
        ];
        // this.drawTriangle(points);
        this.touch1 = this.calculateAngles(points);
        this.checkTriangleSimilarity(points);
      }
    },
    checkTriangleSimilarity(points) {
      const userTriangleAngles = this.calculateAngles(points);
      const tolerance = 3;
      let res = "";
      let i = 1;
      this.triangles.forEach((item) => {
        if (this.isSimilarTriangle(userTriangleAngles, item.value, tolerance)) {
          res = item.name;
          this.stamp = i;
        }

        i++;
      })
      MessagePlugin.success(`${res}`);
      return res;
    },
    distance(point1, point2) {
      return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
    },
    angle(a, b, c) {
      return Math.acos((b * b + c * c - a * a) / (2 * b * c)) * (180 / Math.PI);
    },
    calculateAngles(points) {
      const a = this.distance(points[0], points[1]);
      const b = this.distance(points[1], points[2]);
      const c = this.distance(points[2], points[0]);

      const angleA = this.angle(a, b, c);
      const angleB = this.angle(b, c, a);
      const angleC = this.angle(c, a, b);

      return [angleA, angleB, angleC];
    },
    isSimilarTriangle(userAngles, presetAngles, tolerance) {
      userAngles.sort();
      presetAngles.sort();
      return Math.abs(userAngles[0] - presetAngles[0]) < tolerance &&
          Math.abs(userAngles[1] - presetAngles[1]) < tolerance &&
          Math.abs(userAngles[2] - presetAngles[2]) < tolerance;
    },
    drawTriangle(points) {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      let rate = 0.5;
      let translateY = -100;
      this.ctx.beginPath();
      this.ctx.moveTo(points[0].x * rate, points[0].y * rate + translateY);
      this.ctx.lineTo(points[1].x * rate, points[1].y * rate + translateY);
      this.ctx.lineTo(points[2].x * rate, points[2].y * rate + translateY);
      this.ctx.closePath();
      this.ctx.stroke(); // 绘制三角形轮廓
    },
    initCanvas(width = this.canvasWidth, height = this.canvasHeight) {
      const canvas = this.$refs.triangleCanvas;
      if (!canvas) return;
      let dpr = window.devicePixelRatio || 1;
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
      this.canvasWidth = width;
      this.canvasHeight = height;
      this.ctx = canvas.getContext('2d');
      this.ctx.scale(dpr, dpr);
      this.ctx.clearRect(0, 0, width, height);
    },
  }
};
</script>
<style src="./../assets/css/StampDemo.css">

</style>
